// Jo Malone Russian Fonts

// Lars Malone - Regular
@font-face {
  font-family: 'Cyrillic Lars Malone';
  src: local('Cyrillic Lars Malone'), local('Cyrillic Lars-Malone'),
    url('#{$fonts-path}cyrillic/LarsMalonePro/LarsMalonePro-RegularWeb.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

// Lars Malone - Bold
@font-face {
  font-family: 'Cyrillic Lars Malone';
  src: local('Cyrillic Lars Malone Bold'), local('Cyrillic Lars-Malone-Bold'),
    url('#{$fonts-path}cyrillic/LarsMalonePro/LarsMalonePro-BoldWeb.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

// Spectral - Light
@font-face {
  font-family: 'Spectral';
  src: local('Spectral'), local('Spectral'), url('#{$fonts-path}cyrillic/Spectral/Spectral-Light.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}
