.footer {
  .sitewide-footer-formatter {
    &__term-link {
      padding-#{$ldirection}: 0;
      display: block;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 33px;
        display: inline-block;
      }
    }
  }
}

.error {
  color: $color-error;
  a {
    color: $color-error;
  }
}

.elc-responsive-modal-mask {
  .elc-product-notify-me-pop-up-wrapper {
    .elc-product-notify-me-content {
      .elc-product-notify-me-notice-wrapper {
        .elc-product-notify-me-gdpr-read-confirmation {
          line-height: 2;
        }
      }
    }
  }
}

.sd-product-grid {
  .elc-product-name-wrapper,
  .elc-product-display-name-wrapper {
    max-width: 230px;
  }
}
