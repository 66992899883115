.social-login {
  &__container {
    .social-network {
      &__button {
        &-facebook {
          background: #{$color-blue-darker} url('/media/images/social_login/fb_icon.svg') 10px center no-repeat;
          background-size: 30px 30px;
          .gnav-account & {
            background-position: center center;
          }
        }
        &-vkontakte {
          background: #{$color-blue-dark} url('/media/images/social_login/vk_icon.svg') 10px center no-repeat;
          background-size: 30px 30px;
          .gnav-account & {
            background-position: center center;
          }
        }
      }
    }
  }
}
.wrapper,
.gnav-util-overlay {
  .social-login {
    &__delimeter {
      &--horizontal {
        .social-login-title {
          background-color: $color-cream;
          top: unset;
          bottom: 10px;
        }
      }
    }
  }
}
